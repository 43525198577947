import styled from 'styled-components';

const Styles = styled.div`
    .logo a, .sidebar-wrapper.ps ul.nav li a.nav-link p {
    //     color: #17a018; 
    //     text-shadow: -1px 1px 0 white,
    //     1px 1px 0 white,
    //    1px -1px 0 white,
    //   -1px -1px 0 white;
    }

    .sidebar .nav li.active > a {
        // color: #17a018 ;
        // opacity: 1;
    }


`;

export default Styles;

// https://coolors.co/498549-3a89bd-17a018-122e12-0c520c-355d78-3274a1-0286de-0b65a3-329932
