import React from 'react';

const VariantsTableButton = () => (
  <span>
    <button
      type="button"
      style={{ height: 20, lineHeight: 0.5 }}
      className="btn btn-info"
    >
      Click here
    </button>
  </span>
);

export default VariantsTableButton;
